<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" @keyup.enter.native="submitForm('ruleForm')">
    <div class="login-box">
      <div class="login-tabs-box">
        <el-tabs v-model="active" @tab-click="changeLogin">
          <!--          <el-tab-pane label="手机号登录" name="mobile"></el-tab-pane>-->
          <el-tab-pane label="公司名登录" name="company"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="tip-error" v-show="errorText">
        <label >{{ errorText }}</label>
      </div>
      <div>
        <div v-if="active==='mobile'">
          <el-form-item class="login-input" prop="mobile">
            <el-input v-model="ruleForm.mobile" :clearable="false" size="medium" placeholder="请输入手机号">
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
              <el-tooltip class="item" slot="suffix" effect="dark" content="记住手机号" placement="top">
                <el-checkbox class="login-input-suffix" v-model="rememberMe"></el-checkbox>
              </el-tooltip>
            </el-input>
          </el-form-item>
        </div>
        <div  v-else>
          <el-form-item class="login-input" prop="companyName">
            <el-input v-model="ruleForm.companyName" :clearable="false" size="medium" placeholder="请输入公司名称" name="companyName">
              <i slot="prefix" class="el-input__icon el-icon-office-building"></i>
              <el-tooltip class="item" slot="suffix" effect="dark" content="记住公司名" placement="top">
                <el-checkbox class="login-input-suffix" v-model="rememberCompanyName"></el-checkbox>
              </el-tooltip>
            </el-input>
          </el-form-item>
          <el-form-item class="login-input" prop="account" name="accountName">
            <el-input v-model="ruleForm.account" clearable size="medium" placeholder="请输入用户">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item class="login-input" prop="pwd">
          <el-input ref="password" v-model="ruleForm.pwd" size="medium" placeholder="请输入密码" type="password" show-password name="password">
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" size="medium" class="submit-login" :loading="loading" @click="submitForm('ruleForm')">登录</el-button>
      </el-form-item>
      <div class="forget-pwd">
        <router-link :to="`/register?redirect=${redirect || ''}`">
          <el-link type="primary" :underline="false" class="forget-pwd-link">注册用户</el-link>
        </router-link>
        <label class="split">|</label>
        <router-link :to="`/forgot-password?redirect=${redirect || ''}`">
          <el-link type="primary" :underline="false" class="forget-pwd-link">找回密码</el-link>
        </router-link>
      </div>
    </div>
  </el-form>
</template>

<script>
import { getCookies, setCookies } from '@/utils/cookie'
import { createSession, getImgCode, sendSms, signInWeb } from '@/api/user'
import { setToken } from '@/utils/auth'

import {setInitStoreData} from "@/utils/store";

const keyMoblie = 'mobile'
const keyCompanyName = 'company-name'
export default {
  data() {
    return {
      loading: false,
      rememberMe: false,
      rememberCompanyName: false,
      active: 'company',
      sessionId: '',
      timer: null,
      errorText: '',
      sendSmsText: '发送验证码',
      ruleForm: {
        mobile: '',
        companyName: '',
        account: '',
        pwd: '',
        code: '',
        imgCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'none' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'none' }
        ],
        account: [
          { required: true, message: '请输入用户名', trigger: 'none' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'none' }
        ],
        // imgCode: [
        //   { required: true, message: '请输入图片验证码', trigger: 'none' }
        // ],
        // code: [
        //   { required: true, message: '请输入短信验证码', trigger: 'none' }
        // ]
      },
      redirect: undefined,
      otherQuery: null,
      imgUrl: ''
    }
  },
  watch: {
    $route: {
      handler(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  computed: {
    showSendSmsCode() {
      return this.ruleForm.imgCode.length === 4
    }
  },
  created() {
    const { mobile, rememberMe } = getCookies(keyMoblie) || {}
    this.ruleForm.mobile = mobile || ''
    this.rememberMe = rememberMe

    const { companyName, rememberCompanyName } = getCookies(keyCompanyName) || {}
    this.ruleForm.companyName = companyName || ''
    this.rememberCompanyName = rememberCompanyName
    if (mobile) {
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    }
  },
  mounted() {
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    // async createdSession() {
    //   const { id } = await createSession()
    //   this.sessionId = id
    //   getImgCode({ id }).then(src => {
    //     this.imgUrl = src
    //   })
    // },
    // async createImageCode() {
    //   const src = await getImgCode({ id: this.sessionId })
    //   this.imgUrl = src
    // },
    async changeLogin() {
      if (this.active === 'mobile') {
        if (this.ruleForm.mobile) {
          this.$nextTick(() => {
            this.$refs.password.focus()
          })
        }
      }
      if (this.active === 'company' && !this.imgUrl) {
        // await this.createdSession()
        // this.ruleForm.imgCode = ''
        this.$nextTick(() => {
          this.$refs.imgCode.focus()
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { mobile, companyName } = this.ruleForm
          this.loading = true
          let res
          if (this.active === 'mobile') {
            this.rememberMe && setCookies(keyMoblie, { mobile, rememberMe: this.rememberMe })
            // res = await this.loginByPassWd()
          } else {
            this.rememberCompanyName && setCookies(keyCompanyName, { companyName, rememberCompanyName: this.rememberCompanyName })
            res = await this.loginInWeb()
          }
          this.loading = false
          const { code, msg, data } = res
          if (code === 0) {
            const { Expires, AccessToken } = data
            setToken(AccessToken, Expires)

            this.$router.push({ path: this.redirect || '/', query: this.otherQuery })

            await setInitStoreData()
            return
          }
          this.errorText = msg
        } else {
          return false
        }
      })
    },
    // async loginByPassWd() {
    //   try {
    //     const { mobile, password } = this.ruleForm
    //     const ret = await pwdLogin({ mobile, password })
    //     return { code: true, ...ret }
    //   } catch (ex) {
    //     return { code: false, message: ex }
    //   }
    // },
    async loginInWeb() {
      try {
        const { companyName, account, pwd } = this.ruleForm
        const ret = await signInWeb({ companyName, account, pwd })
        const { code } = ret
        return ret
      } catch (ex) {
        return { code: 401, msg: ex }
      }
    },
    async sendSmsCode() {
      if (this.sendSmsText !== '发送验证码') return
      const params = {
        sessionId: this.sessionId,
        mobile: this.ruleForm.mobile,
        imageCode: this.ruleForm.imgCode,
        captchaType: 'Login',
      }
      const res = await sendSms(params)
      const { status, message } = res
      switch (status) {
        case 1:
          this.timerHandler()
          break
        case 2:
          await this.createImageCode()
          break
        case 3:
          await this.createdSession()
          break
        default: break
      }
      this.errorText = message
      // this.$tip(message)
    },
    timerHandler() {
      let time = 59
      this.timer = setInterval(() => {
        this.sendSmsText = `${time--}s`
        if (time === 0 && this.timer) {
          clearInterval(this.timer)
          this.sendSmsText = '发送验证码'
        }
      }, 1000)
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>
<style lang="scss">
.login-box {
  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }
}
</style>


<style lang="scss" scoped>
.login-box {
  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }
}
.tip-error {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
  color: red;
  label {
    font-weight: normal;
  }
}
.login-box {
  background: #fff;
  width: 320px;
  height: 350px;
  padding: 24px;
  box-shadow: 1px 1px 1px 1px #ededed;
  border-radius: 5px;
  .login-tabs-box {
    font-size: 23px;
    line-height: 60px;
    & > div {
      cursor: pointer;
    }
  }
}
.forget-pwd {
  line-height: 20px;
  text-align: right;
}
.image-code-box {
  width: 100px;
  height: 34px;
  overflow: hidden;
  padding: 1px 0px;
  margin-right: -4px;
  cursor: pointer;
  img {
    height: inherit;
    width: inherit;
  }
}
.image-code-input input.el-input__inner {
  padding-right: 100px;
}
.active_tab {
  color: #1890ff;
}
.login-input-suffix {
  line-height: 36px;
  margin-right: 5px;
}
.submit-login {
  width: 100%;
}
.split {
  margin: 0 7px;
  font-weight: 100;
}
</style>
